import React from 'react'

import PageWithLeftMenu from '../../components/PageWithLeftMenu'
import P from '../../components/P'
import SEO from '../../components/seo'

import items from './_items'

export default () => {
  return (
    <PageWithLeftMenu title="VIZIUNE ȘI MISIUNE" items={items}>
      <SEO
        title="Viziune și misiune"
        keywords={[`viziune`, 'misiune', `fundația ebe`]}
      />
      <P>
        <b>Viziune</b>
      </P>
      <P>
        O lucrare dezvoltată de Centre de Pregatire cu baza în Biserica Locală,
        în toată mișcarea evanghelică, unde lideri slujitori ucenicizatori,
        bărbați și femei, se echipează prin pregătire biblică, accesibilă, să
        slujească biserici relevante și multiplicatoare, cu un impact tot mai
        puternic în misiunea mondială.
      </P>

      <P>
        <b>Misiune</b>
      </P>
      <P>
        Contextul actual scoate in evidentă nevoia de “echipatori” cu viziune de
        multiplicare a liderilor slujitori ucenicizatori pentru o lucrare în
        extindere. EBE țintește spre formarea “în lucrare“ nu doar “pentru
        lucrare”, formare la care să contribuie oameni înzestrați de Dumnezeu,
        echipați și împuterniciți în biserică, de către biserică și pentru
        lucrarea bisericii.
      </P>

      <P>
        Deosebitul potențial existent în bisericile române evanghelice de a
        influenta societatea si de a face diferența în misunea mondială depinde
        în mare măsură de calitatea și de numărul liderilor slujitori autentici.
        Iată de ce EBE se implică deplin în pregătirea de lideri slujitori
        ucenicizatori sau multiplicatori.
      </P>

      <P>
        Prin parteneriatele și infrastructura disponibilă la ora actuală și
        beneficiind de călăuzirea și ajutorul lui Dumnezeu, EBE continuă să
        participe la lansarea bisericii române spre un rol semnificativ în ceea
        ce privește îndeplinirea mandatului Marii Însărcinări. Până la această
        dată EBE s-a implicat prin personalul său în lucrarea de misiune
        transculturală, participând la misiuni pe termen scurt de echipare de
        lideri slujitori în diferite țări ca și Vietnam, Moldova, Ucraina,
        Tajikistan, Sudanul de Sud, ș.a.
      </P>

      <P>
        Un deosebit interes și potențial pentru implicare si slujire se remarcă
        de asemenea tot mai mult printre femeile creștine. Ele sunt sursa
        utilizată încă insuficient pentru Împarăția lui Dumnezeu. Femeile mature
        si evlavioase sunt de mare necesitate pentru creșterea sănatoasă a
        trupului spiritual al Domnului Hristos. EBE dezvoltă programe specifice
        pentru lucrarea femeilor.
      </P>
    </PageWithLeftMenu>
  )
}
